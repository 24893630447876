<template>
  <div class="exhibitor-configuration">
    <el-card>
      <el-page-header @back="goBack" style="margin-bottom: 20px;"></el-page-header>
      <el-tabs v-model="active" tab-position="left" @tab-click="handleTab">
        <el-tab-pane label="展商基础信息" name="basicInformation">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-for="item in menu" :label="item.name" :name="item.sub">
              <itemCpn :data="item"></itemCpn>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="展商素材" name="sourceMaterial">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-for="item in menu" :label="item.name" :name="item.sub">
              <itemCpn :data="item"></itemCpn>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="展商海报">
          <div class="poster">
            <div class="left">
              <el-form :model="postForm" label-width="60px" style="flex:1">
                <el-form-item label="图片">
                  <up-img :banner.sync="postForm.logo" :action="action" tipNew>
                    <template #tip>
                      <span>
                        请上传透明背景的logo，格式为png，大小控制在1MB以内，分辨率为800*800px</span>
                    </template>
                  </up-img>
                </el-form-item>
                <el-form-item label="slogan">
                  <el-col :span="12">
                    <el-input placeholder="请输入slogan" v-model="postForm.slogan"></el-input>
                  </el-col>
                </el-form-item>
              </el-form>
              <div class="btn-content">
                <el-button type="primary">保存海报</el-button>

                <el-button type="primary">预览海报</el-button>
              </div>
            </div>
            <div class="right">
              <div class="title1 ">
                <span>预览</span>

              </div>
              <div class="left-content">
                <el-carousel :interval="4000" type="card" height="596px" width="628px">
                  <el-carousel-item v-for="item in 6" :key="item">
                    <img
                      src="https://tse3-mm.cn.bing.net/th/id/OIP-C.k9DXdu8F-MlCIkFNN6TkpwHaNK?w=182&h=324&c=7&r=0&o=5&pid=1.7"
                      alt="">
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

    </el-card>
  </div>
</template>
<script>
import itemCpn from "@/views/tool/exhibitor/item-cpn.vue";
export default {
  data() {
    return {
      activeName: 'first',
      active: 'basicInformation',
      exhibitionId: "",
      exhibitorId: "",
      menu: [],
      postForm: {
        img: '',
        slogan: ''
      },
      action: {
        action: "system"
      }
    };
  },
  components: {
    itemCpn
  },
  created() {
    this.exhibitionId = this.$route.query.exhibitionId
    this.exhibitorId = this.$route.query.exhibitorId
    console.log(this.exhibitionId, this.exhibitorId)
    this.getData('basicInformation')
  },

  methods: {
    handleTab(e) {
      console.log(e);
      if (e.name) {
        this.getData(e.name)
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async getData(type) {
      let { data: res } = await this.$http.get("/admin/Exhibition/getBasicInformationConf", {
        params: {
          exhibition_id: 1,
          type: type
        }
      })
      this.menu = res.data
      this.activeName = this.menu[0].sub
    }
  },
};
</script>
<style scoped lang="less">
.poster {
  display: flex;
  min-height: 770px;

  .left {
    width: 49%;
    height: 570px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    .left-content {
      text-align: center;
      margin-top: 69px;

      img {
        width: 336px;
        height: 596px;
      }
    }
  }

  .right {
    width: 49%;
    min-height: 770px;

    .title1 {
      display: flex;
      justify-content: space-between;
    }

    .left-content {
      text-align: center;
      margin-top: 69px;

      img {
        width: 336px;
        height: 596px;
      }
    }
  }
}
</style>